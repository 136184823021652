const SERVER_HOST = process.env.REACT_APP_API_URL;

const GET_USERS = `${SERVER_HOST}/users`;
const GET_SUBSCRIBERS = `${SERVER_HOST}/subscribers`;
const CHANGE_PASSWORD = `${SERVER_HOST}/users/change-password`;
const FORGOT_PASSWORD = `${SERVER_HOST}/users/forgot-password`;
const RESET_PASSWORD = `${SERVER_HOST}/users/reset-password`;
const DELETE_USER = `${SERVER_HOST}/users`;
const DELETE_SUBSCRIBER = `${SERVER_HOST}/subscribers`; 

export { GET_USERS, GET_SUBSCRIBERS, CHANGE_PASSWORD, FORGOT_PASSWORD, RESET_PASSWORD, DELETE_USER, DELETE_SUBSCRIBER  };
