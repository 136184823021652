import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';

// layouts
import DefaultLayout from '../layouts/Default';
import VerticalLayout from '../layouts/Vertical';
import HorizontalLayout from '../layouts/Horizontal/';

// components
import PrivateRoute from './PrivateRoute';
import Root from './Root';

// constants
import { LayoutTypes } from '../constants';

// hooks
import { useRedux } from '../hooks';

// lazy load all the views
// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const Register = React.lazy(() => import('../pages/auth/Register'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const ChangePassword = React.lazy(() => import('../pages/auth/ChangePassword'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const ChangePasswordSuccess = React.lazy(() => import('../pages/auth/ChangePasswordSuccess'))
const OtpSentSuccess = React.lazy(() => import('../pages/auth/OtpSentSuccess'))
const ResetPassword = React.lazy(() => import('../pages/auth/ResetPassword'));

// dashboards
const DashBoard1 = React.lazy(() => import('../pages/dashboards/DashBoard1/'));

// apps
const Parents = React.lazy(() => import('../pages/parents'));
const Sitters = React.lazy(() => import('../pages/sitters'));
const Subscribers = React.lazy(() => import('../pages/subscribers'));
const EmailTemplate = React.lazy(() => import('../pages/emailTemplate'));

const Error404 = React.lazy(() => import('../pages/other/Error404'));
const Error500 = React.lazy(() => import('../pages/other/Error500'));
const Maintenance = React.lazy(() => import('../pages/other/Maintenance'));
const ComingSoon = React.lazy(() => import('../pages/other/ComingSoon'));
const Profile = React.lazy(() => import('../pages/apps/Contacts/Profile'));

// lamding
const Landing = React.lazy(() => import('../pages/Landing'));

const loading = () => <div className=""></div>;

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Component />
    </Suspense>
);

const AllRoutes = () => {
    const { appSelector } = useRedux();

    const { layout } = appSelector((state) => ({
        layout: state.Layout,
    }));

    const getLayout = () => {
        let layoutCls: React.ComponentType = VerticalLayout;

        switch (layout.layoutType) {
            case LayoutTypes.LAYOUT_HORIZONTAL:
                layoutCls = HorizontalLayout;
                break;
            default:
                layoutCls = VerticalLayout;
                break;
        }
        return layoutCls;
    };
    let Layout = getLayout();

    return useRoutes([
        { path: '/', element: <Root /> },
        {
            // public routes
            path: '/',
            element: <DefaultLayout />,
            children: [
                {
                    path: 'auth',
                    children: [
                        { path: 'login', element: <LoadComponent component={Login} /> },
                        { path: 'register', element: <LoadComponent component={Register} /> },
                        { path: 'confirm', element: <LoadComponent component={Confirm} /> },
                        { path: 'forget-password', element: <LoadComponent component={ForgetPassword} /> },
                        { path: 'change-password', element: <LoadComponent component={ChangePassword} /> },
                        { path: 'logout', element: <LoadComponent component={Logout} /> },
                        { path: 'change-password-success', element: <LoadComponent component={ChangePasswordSuccess} />},
                        { path: 'otp-sent-success', element: <LoadComponent component={OtpSentSuccess} />},
                        { path: 'reset-password', element: <LoadComponent component={ResetPassword} />},
                    ],
                },
                {
                    path: 'error-404',
                    element: <LoadComponent component={Error404} />,
                },
                {
                    path: 'error-500',
                    element: <LoadComponent component={Error500} />,
                },
                {
                    path: 'maintenance',
                    element: <LoadComponent component={Maintenance} />,
                },
                {
                    path: 'coming-soon',
                    element: <LoadComponent component={ComingSoon} />,
                },
                {
                    path: 'landing',
                    element: <LoadComponent component={Landing} />,
                },
            ],
        },
        {
            // auth protected routes
            path: '/',
            element: <PrivateRoute roles={'Admin'} component={Layout} />,
            children: [
                {
                    path: 'dashboard',
                    element: <LoadComponent component={DashBoard1} />,
                },
                {
                    path: 'contacts/profile',
                    element: <LoadComponent component={Profile} />,
                },
                {
                    path: 'parents',
                    element: <LoadComponent component={Parents} />,
                },
                {
                    path: 'sitters',
                    element: <LoadComponent component={Sitters} />,
                },
                {
                    path: 'subscribers',
                    element: <LoadComponent component={Subscribers} />,
                },
                {
                    path: 'email-template',
                    element: <LoadComponent component={EmailTemplate} />,
                },
            ],
        },
    ]);
};

export { AllRoutes };
