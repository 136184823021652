// all routes
import Routes from './routes/Routes';

// helpers
// For Default import Theme.scss
import './assets/scss/Theme.scss';
import { ToastContainer, Flip, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import the styles

const App = () => {
    return (
        <>
            <Routes />
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            {/* Same as */}
            <ToastContainer />
        </>
    );
};

export default App;
